<template>
  <!-- 明細 -->
  <div>
    <div class="addPerson__wrap" v-show="visible">
      <el-header class="shadow page-header__wrap">
        <div class="flex">
          <div class="backBtn" @click="handleReturn">
            <el-image class="back-img" :src="require('@/assets/img/back.png')"></el-image>
            <span>返回</span>
          </div>
          <div class="title">
            <span class="site">当前位置：</span>
            {{ $route.meta.title }}
            <span> > </span>
            下单明细
          </div>
        </div>
      </el-header>
      
      <div class="main">
        <div class="mainBox">
          <div class="itemTime">订单明细</div>
          <el-table
            :data="table.data"
            style="width: 100%"
            :row-key="row => row.id"
            border>
            <el-table-column
              type="index"
              label="序号"
              width="60">
            </el-table-column>
            <el-table-column property="norm_name" label="标准名称" width="150"></el-table-column>
            <el-table-column property="store_name" label="数据来源" width="150">
              <template slot-scope="{row}">
                <span v-if="!!row.store_name">{{row.store_name}}</span>
                <span v-else>{{row.source == 1 ? '销售订单' : '平台自采'}}</span>
              </template>
            </el-table-column>
            <!-- 已采购的展示采购单号 -->
            <el-table-column property="purchase_no" label="采购单号" width="190">
              <template slot-scope="{row}">
                <el-link type="primary" @click="showDetail(row)" >{{row.purchase_no}}</el-link>
              </template>
            </el-table-column>
            <el-table-column property="num" label="下单数量">
              <template slot-scope="{row}">
                <span>{{row.num}}{{currRow.unit_name}}</span>
              </template>
            </el-table-column>
            <el-table-column property="use_inventory" label="使用库存">
              <template slot-scope="{row}">
                <span>{{row.use_inventory}}</span><span v-if="row.use_inventory > 0">{{row.unit_name}}</span>
              </template>
            </el-table-column>
            <el-table-column property="use_inventory" label="计算损耗数量">
              <template slot-scope="{row}">
                <span>{{row.loss_num}}</span>
              </template>
            </el-table-column>
            <el-table-column property="purchase_num" label="需采购数量" width="150">
              <template slot-scope="{row}">
                <span>{{row.purchase_num}}{{row.unit_name}}</span>
              </template>
            </el-table-column>
            <el-table-column property="create_time" label="创建时间" width="140"></el-table-column>
            <el-table-column property="remark" label="备注" width="160">
              <template slot-scope="{row}">
                <div v-for="(v,i) in row.remark" :key="i">{{v}}。</div>
              </template>
            </el-table-column>
          </el-table>
          <div class="pageWrap" v-if="table.total > 10">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="table.params.page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="table.params.count"
              layout="total, sizes, prev, pager, next, jumper"
              :total="table.total">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- 采购单详情 -->
    <PurchaseDetail ref="purchasedetail" ></PurchaseDetail>

  </div>
</template>

<script>
  import PurchaseDetail from '@/views/purchase/list/components/Details.vue'
  import { mixinTable } from '@/mixins/table.js'
  export default {
    name: 'GatherDetail',
    props:['sleDate'],
    mixins: [mixinTable],
    components: {
      PurchaseDetail
    },
    data() {
      return {
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        table: {
          params:{
            page:1,
            count:10,
          },
          total: 0,
          data:[],
        },
        cateOpt: [],
        currRow: {},
        is_self: 1,// 类型 1:订单详情 2:自采详情
      }
    },
    methods: {
      getDetail(row) {
        this.currRow = row;
        this.getTable()
      },
      reset(done) {
        this.isChange = false
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 单品汇总详情
      getTable() {
        let _params = {
          ...this.table.params,
          goods_name: this.currRow.good_name, // 商品名称
          unit_name: this.currRow.unit_name, //单位
          uni_spec: this.currRow.uni_spec, //区分标识
          supplier_id: this.currRow.bind_id == 0 ? '-1' : this.currRow.bind_id , //供应商/采购员ID(如果没有采购员或供应商时传-1)
          address_id: this.sleDate.address_id, //门店ID
          order_cate_id: this.sleDate.order_cate_id, //下单分类ID
          start: !!this.sleDate.date ? this.sleDate.date[0] : '',
          end: !!this.sleDate.date ? this.sleDate.date[1] : '',
          status: 2, // 状态 1:待确认 2:已确认
          is_self: 1, // 类型 1:订单详情 2:自采详情
        }
        this.$http.get("/admin/purchase_order/goodsInfo",{params: _params}).then((res) => {
          if (res.code === 1) {
            this.table.loading = false;
            this.table.data = res.data.list
            this.table.total = res.data.total;
          }
        })
      },
      // 采购单详情
      showDetail(row) {
        let dom = this.$refs.purchasedetail;
        let obj = {
          id: row.purchase_id, //采购单id
          showType: 9999, // 公用组件，不展示底部操作按钮
        }
        dom.toggle(true);
        dom.getDetail(obj);
        dom = null;
      },
      // 返回
      handleReturn() {
        this.$emit("refresh")
        this.toggle(false)
        this.table.data = [];
      },
    }
  }
</script>

<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
} 
.addPerson__wrap .main {
  overflow-x: hidden;
  background: #fff;
}
.returnBtn {
  z-index: 99;
  box-shadow: 0 0 8px 0 rgb(232 237 250 / 60%), 0 2px 4px 0 rgb(232 237 250 / 50%);
}
.footerWrap {
  text-align: center;
  padding: 10px;
   box-shadow: 0 0 8px 0 rgb(232 237 250 / 60%), 0 2px 4px 0 rgb(232 237 250 / 50%);
  z-index: 99;
}
.itemTime {
  display: block;
  margin-bottom: 10px;
  height: 30px;
  line-height: 30px;
}
.itemTime::before {
  content: "";
  width: 5px;
  height: 15px;
  background: #2cb167;
  border-radius: 6px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 2px;
}
.flex {
  display: flex;
}
.mt20 {
  margin-top: 20px;
}
.mb20 {
  margin-bottom: 20px;
}
.width200px {
  width: 200px;
}
.after-table {
  padding: 10px;
  font-size: 13px;
  color: #303030;
  text-align: right;
}
.pageWrap {
  margin-top: 10px;
  text-align: right;
  margin-right: 36px;
}
</style>
